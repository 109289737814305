<template>
  <div class="duplicate-patient-form">
    <div class="duplicate-patient">
      <div>
        <div class="title">
          ({{ patientData.body.duplicatePatients.length }})
          Already Existing Patients
        </div>
        <div>
          <div>
            <label>Name:</label>
            {{ patientData.selectedDuplicatePatient.name }}
          </div>
          <div>
            <label>Date of Birth:</label>
            {{ patientData.selectedDuplicatePatient.date_of_birth }}
          </div>
          <div>
            <label>Gender:</label>
            {{ patientData.selectedDuplicatePatient.gender }}
          </div>
          <div>
            <label>Last 4 SSN:</label>
            {{ patientData.selectedDuplicatePatient.ssn_last_4 }}
          </div>
          <div>
            <label>Facility:</label>
            {{ patientData.selectedDuplicatePatient.facility }}
          </div>
        </div>
        <div class="select-duplicate">
          <select-box
            :value="patientData.selectedDuplicatePatient"
            :items="patientData.body.duplicatePatients"
            label="Duplicate Patient Facilities"
            clearable="true"
            class="col-sm-11 mt-2"
            @change="updatedDuplicateField"
          ></select-box>
        </div>
      </div>
      <div>
        <div class="title">
          Create new patient
        </div>
        <div>
          <label>Name:</label>
          {{ patientData.body.enteredPatient.first_name || '' }}
          {{ patientData.body.enteredPatient.middle_initial || '' }}
          {{ patientData.body.enteredPatient.last_name || '' }}
          {{ patientData.body.enteredPatient.suffix || '' }}
        </div>
        <div>
          <label>Date of Birth:</label>
          {{ $date(patientData.body.enteredPatient.dob).format('MM/DD/YYYY') }}
        </div>
        <div>
          <label>Gender:</label>
          {{ patientData.body.enteredPatient.gender }}
        </div>
        <div>
          <label>Last 4 SSN:</label>
          {{ patientData.body.enteredPatient.ssn_last_4 }}
        </div>
        <div>
          <label>Facility:</label>
          {{ patientData.body.enteredPatient.facility }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    patientData: {
      type: Object,
      default: () => ({
        body: {
          enteredPatient: {
            first_name: '',
            middle_initial: '',
            last_name: '',
            suffix: '',
            dob: '',
            gender: '',
            ssn_last_4: '',
            facility: '',
          },
          duplicatePatients: [],
        },
        selectedDuplicatePatient: {},
      }),
    },
  },
  data() {
    return {
      duplicatePatients: null,
    }
  },
  created() {
    this.patientData.selectedDuplicatePatient = { ...this.patientData.body.duplicatePatients[0] }
    this.$emit('update:selectedDuplicatePatient', this.patientData.selectedDuplicatePatient)

    this.duplicatePatients = this.patientData.body.duplicatePatients
  },
  methods: {
    updatedDuplicateField(value) {
      this.patientData.selectedDuplicatePatient = this.duplicatePatients.find(
        patient => patient.id === value,
      )
      this.$emit('update:selectedDuplicatePatient', this.patientData.selectedDuplicatePatient)
    },
  },
}
</script>
