export default {
  methods: {
    sanitizeEncounter() {
      if (this.$custom.isObject(this.encounter)) {
        // Wound care
        if (this.encounter.practice_type_id === 1) {
          /* Not needed with new UI changes for medical histories and risk factors
          // Remove blank medical histories
          if (this.$custom.isArray(this.encounter.medical_histories)) {
            this.encounter.medical_histories = [...this.encounter.medical_histories.filter(x => x.id !== null)]
          }

          // Remove blank risk factors
          if (this.$custom.isArray(this.encounter.risk_factors)) {
            this.encounter.risk_factors = [...this.encounter.risk_factors.filter(x => x.id !== null)]
          }
          */
        }

        // Psychology
        if (this.encounter.practice_type_id === 3) {
          //
        }

        // If encounter deleted, empty object except for required values
        if (this.encounter.deleted === true) {
          this.encounter = {
            id: this.encounter.id,
            deleted: true,
          }
        }
      }
    },
  },
}
